import http from './http'
import { isProduction } from '../constant/config'

export async function oss(params: { filename: string }): Promise<{
  accessId: string
  expireTime: number
  host: string
  key: string
  policy: string
  signature: string
}> {
  if (!isProduction) {
    return Promise.resolve({
      accessId: 'LTAI4GA2J86MevDgAxGQiMTb',
      expireTime: 1666010438958,
      host: 'https://miniprogram.oss-cn-shenzhen.aliyuncs.com',
      key: 'web/poster/202210/16660098389579a9c65831df784bf03a7b2bf4cc0615e.png',
      policy:
        'eyJleHBpcmF0aW9uIjoiMjAyMi0xMC0xN1QxMjo0MDozOC45NThaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjBdLFsic3RhcnRzLXdpdGgiLCIka2V5Iiwid2ViL3Bvc3Rlci8yMDIyMTAvMTY2NjAwOTgzODk1NzlhOWM2NTgzMWRmNzg0YmYwM2E3YjJiZjRjYzA2MTVlLnBuZyJdXX0=',
      signature: 'u61hqLwqR+bdkWPGzQZZMnGf4uI='
    })
  }
  return http.post('/web/poster/oss', params).then(res => res.data)
}

export async function createOrder(params: {
  date: number
  people: string
  mobile: string
  address: string
  h5: boolean
  products: {
    id: number
    from: string
    name: string
    count: number
    spec?: string
    specId?: number
  }[]
  /** diy订单 */
  diy: Moonrise.Diy.Order[]
}) {
  return new Promise((resolve, reject) => {
    // setTimeout(() => {
    //   resolve({
    //     data: {
    //       url: 'https://static-cos.lycheer.net/xupload/20221025_1.jpg',
    //       id: 'simsirtttttt00',
    //       total: 25000
    //     }
    //   })
    // }, 3000)
    http
      .get<{
        key: string
      }>('/web/order/pre')
      .then(res => {
        http
          .post<{
            url: string
            id: string
            total: number
          }>('/web/order', {
            ...params,
            key: res.data.key
          })
          .then(resolve)
          .catch(reject)
      })
      .catch(reject)
  })
}

export function checkOrder(id: string) {
  return http.get<{
    pay: boolean
  }>(`/web/order/check/${id}`)
}

export function fetchOrderDisabledDate() {
  return http.get<{
    disabledDate: number[]
  }>(`/web/order/date`)
}