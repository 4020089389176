/* eslint-disable @typescript-eslint/no-explicit-any */
import { host } from '.././constant/config'
// import { Message } from '@arco-design/web-react'

import Axios from 'axios'

// const TOKEN_HEADER_KEY = 'x-access-token'
const ERROR_STATUS_CODE = [500, 404, 422, 403]
const ERROR_MESSAGE = ['服务器出错', '暂无接口', '参数异常', '登录已失效']
const instance = Axios.create({
  baseURL: host
})
instance.defaults.withCredentials = true
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // config.headers[TOKEN_HEADER_KEY] = get(keys.token) || ''
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  res => {
    // if (res.headers[TOKEN_HEADER_KEY]) {
    //   // set(keys.token, res.headers[TOKEN_HEADER_KEY], 1.5 * 60 * 60 * 1000)
    // }
    if (res.status === 200 && !res.data.msg) {
      return Promise.resolve(res.data)
    }
    return Promise.reject({
      status: res.request.status,
      msg: res.data.msg
    })
  },
  error => {
    let msg = JSON.stringify(error)
    if (ERROR_STATUS_CODE.indexOf(error.request.status) >= 0) {
      msg = ERROR_MESSAGE[ERROR_STATUS_CODE.indexOf(error.request.status)]
    }
    if (error.request.response) {
      let json = JSON.parse(error.request.response)
      if (json.msg) {
        msg = json.msg
      }
    }
    // Message.error({
    //   duration: 10000,
    //   content: msg
    // })
    return Promise.reject({
      code: error.request.status,
      msg: msg
    })
  }
)

export default instance
