import { Spin } from '@arco-design/web-react'
import './index.css'

export default function Loading() {
  return (
    <div className="loading flex-center">
      <Spin size={24} />
    </div>
  )
}
