import { configureStore } from '@reduxjs/toolkit'
import { IAppState, appSlice } from './app'
import { ICartState, cartSlice } from './cart'
import { IDiyState, diySlice } from './diy'

export interface IState {
  app: IAppState,
  cart: ICartState,
  diy: IDiyState
}

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    cart: cartSlice.reducer,
    diy: diySlice.reducer
  }
})

// 添加状态监听值
let unsubscribe = store.subscribe(() => {
  console.log('store 的状态发生了变化', store.getState())
})
// 取消监听
unsubscribe()

export default store
