import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { PRODUCTION_COST } from '../constant/diy'

export interface IDiyState {
  total: number
  totalAndProductionCost: number
  size?: Moonrise.Package.Size
  sizeColor: number
  package?: Moonrise.Package.Item
  message: Moonrise.Diy.Message
}

export const diySlice = createSlice<IDiyState, SliceCaseReducers<IDiyState>>({
  name: 'diy',
  initialState: {
    sizeColor: 0,
    total: 0,
    totalAndProductionCost: 0,
    message: {
      to: '',
      from: '',
      text: ''
    }
  },
  reducers: {
    updateSize: (
      state: IDiyState,
      action: {
        payload: Moonrise.Package.Size
      }
    ) => {
      state.sizeColor = 0
      state.size = action.payload
    },
    updateSizeColor: (
      state: IDiyState,
      action: {
        payload: number
      }
    ) => {
      state.sizeColor = action.payload
    },
    updatePackage: (
      state: IDiyState,
      action: {
        payload: Moonrise.Package.Item
      }
    ) => {
      state.package = action.payload
      let colorIndex = -1
      if (state.size && action.payload.pcid) {
        colorIndex = state.size.color.findIndex(color => color.id === action.payload.pcid)
      }
      state.sizeColor = colorIndex >= 0 ? colorIndex : 0
      let total = action.payload.flower.reduce((total, item) => {
        if (item.color && item.color.id) {
          total += Number(item.color.price)
        } else {
          total += Number(item.price)
        }
        return total
      }, 0)
      state.total = Number(total.toFixed(2))
      state.totalAndProductionCost = Number((total + total * PRODUCTION_COST).toFixed(2))
    },
    clearPackage: (state: IDiyState) => {
      state.package = undefined
    },
    switchPackageFlower: (
      state: IDiyState,
      action: {
        payload: {
          index: number
          target: Moonrise.Package.Flower
        }
      }
    ) => {
      const { index, target } = action.payload
      if (state.package) {
        let total = state.total
        const origin = state.package.flower[index]
        total -= origin.color && origin.color.id ? origin.color.price : origin.price
        total += target.color && target.color.id ? target.color.price : target.price
        state.package.flower[index] = target
        state.total = Number(total.toFixed(2))
        state.totalAndProductionCost = Number((total + total * PRODUCTION_COST).toFixed(2))
      }
    },
    updateMessage: (
      state: IDiyState,
      action: {
        payload: Tool.Partial<Moonrise.Diy.Message>
      }
    ) => {
      const { message } = state
      state.message = {
        ...message,
        ...action.payload
      }
    },
    clear: (state: IDiyState) => {
      state.total = 0
      state.totalAndProductionCost = 0
      state.size = undefined
      state.package = undefined
    }
  }
})
