import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { QRCodeCanvas } from 'qrcode.react'
import { Message, Button } from '@arco-design/web-react'
import { IconCheckCircle } from '@arco-design/web-react/icon'
import './index.scss'

import { useCart, useAddress, useCartFromCategory } from '../../../hook/cart'
import { cartSlice } from '../../../store/cart'

import { createOrder, checkOrder } from '../../../service/index'
import { isMobile, isWeChat } from '../../../utils/rem'

import CheckIcon from './check-icon'
import Counter from '../../../ui/counter'
import Price from '../../../ui/price'
import ScaleFont from '../../../ui/scale-font'
import MoonriseIcon from '../../../components/moonrise-icon'

import CartCalendar from './calendar'

const USER_ORDERS = 'USER_ORDERS'
let CHECK_ORDER_TIMER: any
let CART_STEP_TEMP: number = 1

export default function Cart() {
  const dispatch = useDispatch()
  const cart = useCart()
  const cartFromCategory = useCartFromCategory()
  const address = useAddress()

  const [step, setStep] = useState(CART_STEP_TEMP)

  const [selectDate, setSelectDate] = useState<Date>()
  const [ordering, setOrdering] = useState(false)
  const [order, setOrder] = useState<{
    url: string
    id: string
    total: number
  }>()
  const [orderQRCode, setOrderQRCode] = useState()

  const setCache = useCallback((id: string) => {
    const cache = window.localStorage.getItem(USER_ORDERS) || ''
    const orders = cache.split('|').filter(item => item)
    if (orders.indexOf(id) >= 0) {
      return
    }
    orders.push(id)
    window.localStorage.setItem(USER_ORDERS, orders.length === 1 ? orders[0] : orders.join('|'))
  }, [])

  const submit = useCallback(() => {
    let msg = ''
    if (!selectDate) {
      msg = '请选择收货日期'
    }
    if (!msg && !address.name) {
      msg = '请输入联系人'
    }
    if (!msg && !address.moblie) {
      msg = '请输入电话号码'
    }
    if (!msg && !/^1\d{10}$/.test(address.moblie)) {
      msg = '请输入正确的电话号码'
    }
    if (!msg && !address.address) {
      msg = '请输入详细地址'
    }
    if (msg) {
      Message.normal(msg)
      return
    }
    setOrdering(true)
    const diy: Moonrise.Diy.Order[] = []
    const diyList = cart.list.filter(item => item.checked).filter(item => item.from === 'diy')
    const products = cart.list
      .filter(item => item.checked)
      .filter(item => item.from !== 'diy')
      .map(item => {
        return {
          id: item.id,
          from: item.from,
          name: item.name,
          count: item.count,
          spec: item.spec,
          specId: item.specId
        }
      })
    if (diyList.length) {
      diyList.forEach(diyItem => {
        const sort: { id: number; cid: number }[] = []
        if (diyItem.flower && diyItem.flower.length) {
          diyItem.flower.forEach(flower => {
            let spec = undefined,
              specId = undefined
            if (flower.color.id) {
              spec = flower.color.text || ''
              specId = flower.color.id
            }
            sort.push({
              id: flower.id,
              cid: specId ? specId : 0
            })
            products.push({
              id: flower.id,
              from: 'diy',
              name: flower.name,
              count: 1,
              spec,
              specId
            })
          })
        }
        diy.push({
          id: diyItem.id,
          message: diyItem.message as Moonrise.Diy.Message,
          size: diyItem.size || 0,
          sizeColor: diyItem.sizeColor || 0,
          sort: sort
            .map(itemSort => {
              return `${itemSort.id}-${itemSort.cid}`
            })
            .join('&')
        })
      })
    }
    let h5 = isMobile()
    if (isWeChat()) {
      h5 = false
    }
    createOrder({
      date: selectDate ? parseInt(`${selectDate.getTime() / 1000}`) : 0,
      people: address.name,
      mobile: address.moblie,
      address: address.address,
      h5,
      // flowers已混合到products字段
      products,
      diy
    })
      .then((res: any) => {
        if (h5) {
          window.location.href = res.data.url
          return
        }
        Message.error('已创建订单，请支付')
        setOrder(res.data)
        setCache(res.data.id)
        setStep(3)
        if (isWeChat()) {
          setTimeout(() => {
            const canvas: any = document.querySelector(`.nav-cart_order--code canvas`)
            if (canvas) {
              setOrderQRCode(canvas.toDataURL('image/jpg'))
              setOrdering(false)
            }
          }, 1000)
        } else {
          setOrdering(false)
        }
      })
      .catch(err => {
        console.log(err)
        setOrdering(false)
        Message.error('发送失败，请重试')
      })
  }, [address.address, address.moblie, address.name, cart.list, selectDate, setCache])

  const check = useCallback(
    (id: string) => {
      CHECK_ORDER_TIMER = setTimeout(() => {
        checkOrder(id)
          .then(res => {
            if (res.data.pay && step === 3) {
              setStep(4)
            } else {
              check(id)
            }
          })
          .catch(err => {
            console.log(err)
            check(id)
          })
      }, 3000)
    },
    [step]
  )

  useEffect(() => {
    if (step === 2) {
      clearTimeout(CHECK_ORDER_TIMER)
    }
    if (step === 3 && order) {
      check(order.id)
    }
    if (step === 4 && order) {
      Message.normal({
        content: '已支付成功',
        duration: 10000
      })
      // 清空已购买的
      dispatch(
        cartSlice.actions.update(
          cart.list
            .filter(item => item.checked)
            .map(item => {
              return {
                ...item,
                count: 0
              }
            })
        )
      )
    }
    return () => {
      clearTimeout(CHECK_ORDER_TIMER)
    }
  }, [step, order, check, dispatch, cart.list])

  useEffect(() => {
    if ([1, 2].includes(step)) {
      CART_STEP_TEMP = step
    }
    if ([3, 4].includes(step)) {
      CART_STEP_TEMP = 1
    }
  }, [step])

  return (
    <div
      className="nav-cart"
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {(() => {
        switch (step) {
          case 1:
            return (
              <div className="nav-cart_products">
                {cart.list.length ? (
                  <>
                    {cartFromCategory.map(from => {
                      return (
                        <div key={from.from}>
                          {/零售/.test(from.title) ? (
                            <div className="hiragino-sans fw-bold fs-rem-14-20 fs-m-12 pb-3 pb-m-4">{from.title}</div>
                          ) : (
                            <div className="hiragino-sans fw-bold fs-rem-13-18 fs-m-12 pb-3 pb-m-4">{from.title}</div>
                          )}
                          <div className="products pt-rem-8-13 pt-m-2 pb-rem-30-40 pb-m-28">
                            {from.list.map((item, index) => {
                              return (
                                <div className="product" key={index}>
                                  <div
                                    className="w-rem-20-32 width-m-21 flex-shrink fs-0 pt-rem-14-22 pt-m-18"
                                    onClick={() => {
                                      dispatch(
                                        cartSlice.actions.update([
                                          {
                                            ...item,
                                            checked: !item.checked
                                          }
                                        ])
                                      )
                                    }}
                                  >
                                    <CheckIcon value={item.checked}></CheckIcon>
                                  </div>
                                  <div className="product_info pt-rem-12-17 pb-rem-11-14 pt-m-12 pb-m-6 flex-column">
                                    <div className="flex-between">
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                          dispatch(
                                            cartSlice.actions.update([
                                              {
                                                ...item,
                                                checked: !item.checked
                                              }
                                            ])
                                          )
                                        }}
                                      >
                                        <p className="hiragino-sans fs-rem-13-20 lh-rem-13-20 fs-m-13 lh-m-16 fw-bold">
                                          {item.name}
                                          {item.spec ? `(${item.spec})` : ''}
                                        </p>
                                        <p className="optician-sans fs-rem-14-22 lh-rem-20-26 fs-m-14 lh-m-16">
                                          {item.nickname}
                                        </p>
                                      </div>
                                      <Counter
                                        min={0}
                                        value={item.count}
                                        onChange={flag => {
                                          dispatch(
                                            cartSlice.actions.update([
                                              {
                                                ...item,
                                                count: item.count + flag
                                              }
                                            ])
                                          )
                                        }}
                                      ></Counter>
                                    </div>
                                    <div className="mt-auto flex-align-center flex-between">
                                      <div
                                        className="lh-rem-19-25 pointer"
                                        onClick={() => {
                                          dispatch(
                                            cartSlice.actions.update([
                                              {
                                                ...item,
                                                checked: !item.checked
                                              }
                                            ])
                                          )
                                        }}
                                      >
                                        <Price value={item.price} fs={19}></Price>
                                      </div>
                                      <div
                                        className="product_info--remove cursor-pointer"
                                        onClick={() => {
                                          dispatch(
                                            cartSlice.actions.update([
                                              {
                                                ...item,
                                                count: 0
                                              }
                                            ])
                                          )
                                        }}
                                      >
                                        <ScaleFont>
                                          <span
                                            className="hiragino-sans fs-rem-16-22"
                                            style={{
                                              color: '#838383'
                                            }}
                                          >
                                            x Remove
                                          </span>
                                        </ScaleFont>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                    <div className="flex-align-center pb-rem-36-48 pb-m-32">
                      <div
                        className="w-rem-20-32 width-m-21 flex-shrink fs-0 lh-0"
                        onClick={() => {
                          dispatch(cartSlice.actions.checkAll(!cart.checkAll))
                        }}
                      >
                        <CheckIcon value={cart.checkAll}></CheckIcon>
                      </div>
                      <div
                        className="fs-rem-15-20 fw-600 cursor-pointer"
                        onClick={() => {
                          dispatch(cartSlice.actions.checkAll(!cart.checkAll))
                        }}
                      >
                        全选
                      </div>
                      <div className="ml-auto lh-rem-34-45 mt-rem-5-6">
                        <Price value={cart.total} fs={34}></Price>
                      </div>
                    </div>
                    <div className="text-center nav-cart_products--foot">
                      <Button
                        className="moonrise-button optician-sans fs-rem-24-32 fs-m-20"
                        loading={ordering}
                        onClick={() => {
                          if (cart.list.filter(item => item.checked).length <= 0) {
                            Message.normal('请选择要购买的商品')
                            return
                          }
                          setStep(2)
                        }}
                      >
                        ORDER NOW
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="empty flex-center">
                    <span className="optician-sans fs-rem-17-25" style={{ color: 'var(--moonrise-color-gray)' }}>
                      THE CART IS EMPTY
                    </span>
                  </div>
                )}
              </div>
            )
          case 2:
            return (
              <div>
                <div className="nav-cart_calendar pr-rem-26-36 pl-rem-26-36">
                  <div className="nav-cart_calendar--title flex-align-center lh-rem-14-19 pb-rem-8-12 pb-m-6">
                    <div className="hi-sans fw-bold fs-rem-14-19 fs-m-12">收货日期</div>
                  </div>
                  <div className="nav-cart_calendar--body">
                    <CartCalendar
                      value={selectDate}
                      onChange={date => {
                        setSelectDate(date)
                      }}
                    ></CartCalendar>
                  </div>
                </div>
                <div className="nav-cart_address mt-rem-10-23">
                  <div className="nav-cart_address--title flex-align-center lh-rem-14-19 pb-rem-8-12 pb-m-6">
                    <div className="hi-sans fw-bold fs-rem-14-19 fs-m-12">联系方式</div>
                  </div>
                  <div className="input-group-line">
                    <div className="input-group input-group-name">
                      <label className="op-sans fs-rem-16-20 fs-m-12">NAME</label>
                      <input
                        type="text"
                        className="hi-sans fs-rem-17-20 fs-m-12 fw-bold"
                        placeholder=""
                        maxLength={6}
                        value={address.name}
                        onInput={(e: any) => {
                          dispatch(
                            cartSlice.actions.updateAddress({
                              name: e.target.value
                            })
                          )
                        }}
                      />
                    </div>
                    <div className="input-group input-group-tel">
                      <label className="op-sans fs-rem-16-20 fs-m-12">TEL</label>
                      <input
                        type="text"
                        className="hi-sans fs-rem-17-20 fw-bold fs-m-12"
                        maxLength={11}
                        placeholder=""
                        value={address.moblie}
                        onInput={(e: any) => {
                          dispatch(
                            cartSlice.actions.updateAddress({
                              moblie: e.target.value
                            })
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className="input-group-detail">
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                    {address.address ? (
                      ''
                    ) : (
                      <div className="placeholder op-sans fs-rem-16-20 fs-m-12 pointer-none">ADDRESS</div>
                    )}
                    <textarea
                      value={address.address}
                      placeholder=""
                      className="hi-sans fs-rem-17-20 fs-m-12 fw-bold"
                      onInput={(e: any) => {
                        dispatch(
                          cartSlice.actions.updateAddress({
                            address: e.target.value
                          })
                        )
                      }}
                    ></textarea>
                  </div>
                  <div className="text-center mt-rem-34-48 mt-m-30">
                    <Button
                      className="moonrise-button optician-sans fs-rem-24-32 fs-m-20"
                      loading={ordering}
                      onClick={submit}
                    >
                      CHECK
                    </Button>
                    <div className="mt-rem-11-15 mt-m-10">
                      <Button
                        className="moonrise-button moonrise-button-border optician-sans fs-rem-24-32 fs-m-20"
                        onClick={() => {
                          setStep(s => {
                            return s - 1
                          })
                        }}
                      >
                        BACK
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          default:
            return (
              <div className="nav-cart_order">
                {step === 3 && order ? (
                  <>
                    <div className="nav-cart_order--tip pt-rem-30-48 pt-m-30 flex-center fs-rem-24-17">
                      <MoonriseIcon name="icon-scan" style={{ color: '#575757', fontSize: 'inherit' }}></MoonriseIcon>
                      <div className="fs-rem-12-17 lh-rem-12-17 fw-600 ml-rem-20-20 ml-m-16 fs-m-12 lh-m-12">
                        网站内仅支持微信扫码支付
                      </div>
                    </div>
                    <div className="nav-cart_order--code ml-auto mr-auto mt-rem-8-16 mt-m-14">
                      <QRCodeCanvas
                        value={order.url}
                        style={{ width: '100%', height: '100%', opacity: isWeChat() ? 0 : 1 }}
                        level="Q"
                      ></QRCodeCanvas>
                      {isWeChat() && orderQRCode ? <img src={orderQRCode} alt="付款二维码" /> : ''}
                    </div>
                    <div className="pb-rem-10-12 pb-m-10"></div>
                    <div className="nav-cart_order--price flex-between lh-rem-34-45">
                      <span className="optician-sans fs-rem-18-24 lh-rem-40-55 fs-m-15">TOTAL</span>
                      <span>
                        <Price value={order ? Number(order.total / 100) : 0} fs={34}></Price>
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="nav-cart_order--success flex-center flex-column">
                    <div className="flex-align-center">
                      <span className="fs-rem-24-34">
                        <IconCheckCircle className="icon" style={{ fontSize: 'inherit' }} />
                      </span>
                      <span className="hiragino-sans fs-rem-18-25 fw-bold color-fff ml-rem-8-8 ml-m-8">支付成功</span>
                    </div>
                    <div className="text-center hiragino-sans fs-rem-13-17 fw-bold mt-rem-14-14 mt-m-14 tip">
                      当月客服将在10分钟与你取得联系
                    </div>
                  </div>
                )}
                <div className="nav-cart_order--btn mt-rem-20-35 mt-m-26">
                  <Button
                    className="moonrise-button optician-sans fs-rem-24-32"
                    loading={ordering}
                    onClick={() => {
                      setStep(s => {
                        return s - 1
                      })
                    }}
                  >
                    BACK
                  </Button>
                </div>
              </div>
            )
        }
      })()}
    </div>
  )
}
