import { Link, useLocation } from 'react-router-dom'
import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { IState } from '../../store'
import { appSlice } from '../../store/app'
import { useCart } from '../../hook/cart'
import { isPad, isMobile } from '../../utils/rem'
import './index.scss'
import Dialog from './dialog'

export default function Nav() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navStatus = useSelector<IState, boolean>(state => state.app.navStatus)

  const cart = useCart()
  const [visible, setVisible] = useState<{
    info: boolean
    cart: boolean
    mobile: boolean
  }>({
    info: false,
    cart: false,
    mobile: false
  })

  const close = useCallback((type: string) => {
    setVisible(val => {
      return {
        info: type === 'info' ? false : val.info,
        cart: type === 'cart' ? false : val.cart,
        mobile: type === 'mobile' ? false : val.mobile
      }
    })
  }, [])

  useEffect(() => {
    if (location.pathname) {
      dispatch(appSlice.actions.switchNavStatus(false))
    }
  }, [dispatch, location.pathname])

  useEffect(() => {
    if (visible.info || visible.cart || visible.mobile) {
      if (isMobile() || isPad()) {
        document.body.style.overflow = 'hidden'
      }
    } else {
      if (isMobile() || isPad()) {
        document.body.style.overflow = 'visible'
      }
    }
  }, [dispatch, visible])

  return (
    <div
      className={`nav nav-${navStatus ? 'active' : ''}`}
      style={{ backgroundColor: (isPad() || isMobile()) && !navStatus ? 'rgba(0,0,0,0)' : 'var(--moonrise-bg)' }}
    >
      <div
        className="nav_info optician-sans fs-rem-21-33 lh-rem-21-33 fs-m-18 lh-m-18 pointer color-fff"
        style={{ zIndex: !visible.info && visible.cart ? 39 : 0 }}
        onClick={() => {
          setVisible(val => {
            val.info = true
            return {
              ...val
            }
          })
        }}
      >
        INFO
      </div>
      <div className="flex-grow flex-justify-center">
        <div className="nav_link flex-end">
          <Link
            className={`optician-sans fs-rem-25-36 nav_link--btn nav_link--btn-${
              location.pathname === '/flowers' ? 'works' : 'hover'
            }`}
            to="/flowers"
          >
            FLOWERS
          </Link>
        </div>
        <div className="nav_logo">
          <Link to="/">
            <img
              className="nav_logo--img"
              src="https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/logo.png"
              alt="logo"
            />
          </Link>
        </div>
        <div className="nav_link flex-align-end">
          <Link
            className={`optician-sans fs-rem-25-36 nav_link--btn nav_link--btn-${
              location.pathname === '/product' ? 'product' : 'hover'
            }`}
            to="/product"
          >
            PRODUCT
          </Link>
        </div>
      </div>
      <div
        className="nav_cart optician-sans fs-rem-21-33 lh-rem-21-33 fs-m-18 lh-m-18 pointer color-fff"
        style={{ zIndex: !visible.cart && visible.info ? 39 : 0 }}
        onClick={() => {
          setVisible(val => {
            val.cart = true
            return {
              ...val
            }
          })
        }}
      >
        CART({cart.list.length})
      </div>
      <div
        className="nav_hamburger pointer flex-column"
        style={{ zIndex: !visible.mobile && visible.mobile ? 39 : 0 }}
        onClick={() => {
          setVisible(val => {
            val.mobile = true
            return {
              ...val
            }
          })
        }}
      >
        <div></div>
        <div></div>
      </div>
      <Dialog visible={visible} onClose={close}></Dialog>
    </div>
  )
}
