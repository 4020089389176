import Loadable from 'react-loadable'
import Loading from './components/loading'

//通用的过场组件
const loadingComponent = () => {
  return <Loading></Loading>
}

// 过场组件默认采用通用的，若传入了loading，则采用传入的过场组件
// eslint-disable-next-line import/no-anonymous-default-export
export default (loader: any) => {
  return Loadable({
    loader,
    loading: loadingComponent
  })
}