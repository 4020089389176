import { ReactNode } from 'react'
import './index.scss'

export default function ScaleFont(props: {
  height?: number | string
  width?: number | string
  color?: string
  children?: ReactNode
}) {
  const { height = '100%', width = '100%' } = props
  return (
    <div className="scale-font" style={{ height, width }}>
      <div className="scale-font--text" style={{ color: props.color }}>
        {props.children}
      </div>
    </div>
  )
}
