import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'

export interface ICartAddress {
  name: string
  moblie: string
  address: string
}

export interface ICartState {
  list: Moonrise.Cart.Item[]
  total: number
  checkAll: boolean
  address: ICartAddress
}

const CART_CACHE_KEY = 'MOONRISE_CART_CACHE_2023110'
const DEFAULT_CART: ICartState = {
  list: [],
  total: 0,
  checkAll: false,
  address: {
    name: '',
    moblie: '',
    address: ''
  }
}

export const cartSlice = createSlice<ICartState, SliceCaseReducers<ICartState>>({
  name: 'cart',
  initialState: _getCartCache(),
  reducers: {
    update: (
      state: ICartState,
      action: {
        payload: Moonrise.Cart.Item[]
      }
    ) => {
      if (!action.payload.length) {
        return
      }
      action.payload.forEach(sourceitem => {
        const findIndex = state.list.findIndex(item => {
          if (item.specId) {
            return item.from === sourceitem.from && item.id === sourceitem.id && item.specId === sourceitem.specId
          }
          return item.from === sourceitem.from && item.id === sourceitem.id
        })
        if (findIndex >= 0) {
          if (sourceitem.count) {
            state.list[findIndex] = sourceitem
          } else {
            // 移除
            state.list.splice(findIndex, 1)
          }
        } else {
          state.list.push(sourceitem)
        }
      })
      state.checkAll = true
      state.total = state.list
        .filter(item => {
          if (!item.checked && state.checkAll) {
            state.checkAll = false
          }
          return item.checked
        })
        .reduce((val, item) => {
          return val + item.count * item.price
        }, 0)
      _setCartCache(state)
    },
    checkAll: (
      state: ICartState,
      action: {
        payload: boolean
      }
    ) => {
      state.list = state.list.map(item => {
        item.checked = action.payload
        return item
      })
      state.checkAll = action.payload
      state.total = state.list
        .filter(item => item.checked)
        .reduce((val, item) => {
          return val + item.count * item.price
        }, 0)
      _setCartCache(state)
    },
    updateAddress: (
      state: ICartState,
      action: {
        payload: Tool.Partial<ICartAddress>
      }
    ) => {
      state.address = {
        ...state.address,
        ...action.payload
      }
      _setCartCache(state)
    }
  }
})

// 工具方法
function _getCartCache() {
  if (window.localStorage.getItem(CART_CACHE_KEY)) {
    try {
      const c = JSON.parse(window.localStorage.getItem(CART_CACHE_KEY) || '')
      if (c) {
        console.log(c)
        return c
      }
    } catch (error) {
      return DEFAULT_CART
    }
  }
  window.localStorage.setItem(CART_CACHE_KEY, '')
  return DEFAULT_CART
}

function _setCartCache(cart: ICartState) {
  // window.localStorage.setItem(CART_CACHE_KEY, JSON.stringify(cart))
  return cart
}
