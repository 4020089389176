import './index.scss'

import TinyFont from '../../../ui/tiny-font'

const cpright = `${new Date().getFullYear()} Moonrise Flower Studio `
const cprightNumber = '粤ICP备2021077568号'

export default function Info() {
  return (
    <div
      className="nav-info"
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className="nav-info_desc">
        <p className="hiragino-sans fs-rem-14-19 lh-rem-14-19 fs-m-11 fw-bold">当月花艺工作室</p>
        <p className="hiragino-sans fs-rem-14-19 lh-rem-28-36 fs-m-11 lh-m-20 fw-bold mt-16 mt-m-6">
          基于深圳的创意型花艺工作室。是一家静谧，探寻自然本质，以设计加持的美学花艺工作室。挖掘人与自然间互动的可能性，提供更多美好的生活方式，当月为每个在意⽣活的⼈、每个敏感的情绪、每个期待的场景提供专业的定制「回应」。
        </p>
        <p className="hiragino-sans fs-rem-14-19 lh-rem-28-36 fs-m-11 lh-m-20 fw-bold mt-32 mt-m-16">订花须知</p>
        {[
          '- 鲜花仅限深圳同城配送，配送费自理',
          '- 请尽量提前 2-4天 预定',
          '- 花材季节性强，如遇品种缺货将用同价位花材替换',
          '- 鲜花属于生鲜产品，下单后恕不退款'
        ].map((item, index) => {
          return (
            <p
              className={
                'hiragino-sans fs-rem-14-19 lh-rem-28-36 fs-m-11 lh-m-20 fw-bold' + (index === 0 ? ' mt-10 mt-m-4' : '')
              }
              key={index}
            >
              {item}
            </p>
          )
        })}
        <div className="relative">
          <p className="fs-rem-18-24 lh-rem-20-20 fs-m-13 lh-m-13 mt-30 mt-m-18 optician-sans">
            MOONRISE FLOWER STUDIO
          </p>
          <p className="hiragino-sans fs-rem-14-19 lh-rem-28-36 fs-m-11 lh-m-20 fw-bold mt-10 mt-m-16">
            广东省深圳市福田区下梅林
          </p>
          <p className="hiragino-sans fs-rem-14-19 lh-rem-14-19 fs-m-11 lh-m-11 fw-bold">
            锦林新居9栋238-90号
          </p>
          <p className="fs-rem-18-24 lh-rem-20-30 fs-m-13 lh-m-13 mt-14 mt-m-13 optician-sans">OPEN IN 10:00 - 22:00</p>
          <p className="fs-rem-18-24 lh-rem-20-24 fs-m-13 lh-m-13 optician-sans">
            <a href="tel:18682109175">+86 186 8210 9175</a>
          </p>
          <img src="https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/logo2.png" className="logo2" alt="logo" />
        </div>
        <div className="flex mt-24 mt-m-13">
          <div>
            {['WECHAT', 'INSTAGRAM', '小红书'].map((item, index) => {
              return (
                <p
                  className={`optician-sans fs-rem-${index === 2 ? 12 : 18}-${index === 2 ? 17 : 24} fw-${
                    index === 2 ? 600 : 0
                  } lh-rem-${index === 2 ? 24 : 20}-30 fs-m-${index === 2 ? 11 : 13} lh-m-${index === 2 ? 20 : 16}`}
                  key={index}
                >
                  {item}
                </p>
              )
            })}
          </div>
          <div className="ml-30">
            {['MOONRISEFLOWER', 'MOONRISE_FAFA', 'MOONRISE'].map((item, index) => {
              return (
                <p
                  className={`optician-sans fs-rem-18-24 lh-rem-20-30 fs-m-${index === 2 ? 11 : 13} lh-m-${
                    index === 2 ? 20 : 16
                  }`}
                  key={index}
                >
                  {index === 2 ? (
                    <>
                      <span className="optician-sans fs-rem-18-24 fs-m-14 lh-m-14">{item}</span>
                      <span className="hiragino-sans fs-rem-12-17 fs-m-11 lh-m-11 fw-600">当月</span>
                    </>
                  ) : (
                    item
                  )}
                </p>
              )
            })}
          </div>
        </div>
      </div>
      <div className="nav-info_service mt-17 mt-m-10">
        <div className="nav-info_service--images flex">
          <div className="code mt-50 mt-m-30">
            <img src="https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/code.png" alt="code" />
            <p className="hiragino-sans fs-rem-12-16 mt-rem-4-8 lh-0 mobile-hidden">其他需求请咨询微信客服</p>
            <div className="pc-hidden">
              <TinyFont>
                <span className="hiragino-sans fs-m-19">其他需求请咨询微信客服</span>
              </TinyFont>
            </div>
          </div>
          <img
            className="phone ml-33 ml-m-27 mt-10 mt-m-0"
            src="https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/phone.png"
            alt="phone"
          />
        </div>
        <div className="lh-rem-14-19 mt-rem-35-42 mobile-hidden">
          <span className="optician-sans fs-rem-14-19">© </span>
          <span className="hiragino-sans fs-rem-12-16">{cpright}</span>
          <a className="hiragino-sans fs-rem-12-16" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            {cprightNumber}
          </a>
        </div>
        <div className="pc-hidden nav-info_service--cpright">
          <span className="fs-m-12">
            © {cpright}
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
              {cprightNumber}
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}
