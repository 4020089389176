import { useRef, useEffect, ReactNode, useState, CSSProperties } from 'react'
import './index.scss'

export default function TinyFont(props: { children?: ReactNode; style?: CSSProperties }) {
  const div = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    if (div.current && div.current.clientWidth) {
      setWidth(div.current.clientWidth / 2)
      setHeight(div.current.clientHeight / 2)
      // console.log(div.current.clientHeight)
      // console.log(div.current.innerText)
    }
  }, [div])

  return (
    <div className="tiny-font" style={height > 0 ? { height, width, ...props.style } : {}}>
      <div className="tiny-font-placeholder" ref={div}>
        {props.children}
      </div>
      <div className="tiny-font--text">{props.children}</div>
    </div>
  )
}
