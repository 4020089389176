export default function Price(props: { value: number; fs?: number; mfs?: number; color?: string }) {
  const { fs = 19, mfs = 0, value } = props
  const map: Record<string, string[]> = {
    18: ['fs-rem-12-20', 'fs-rem-18-31', 'fs-m-12', 'fs-m-18'],
    19: ['fs-rem-12-14', 'fs-rem-19-25', 'fs-m-12', 'fs-m-19'],
    20: ['fs-rem-13-16', 'fs-rem-20-27', 'fs-m-12', 'fs-m-20'],
    22: ['fs-rem-14-17', 'fs-rem-22-27', 'fs-m-14', 'fs-m-22'],
    23: ['fs-rem-13-23', 'fs-rem-23-38'],
    26: ['fs-rem-18-32', 'fs-rem-26-48', 'fs-m-18', 'fs-m-26'],
    28: ['', '', 'fs-m-18', 'fs-m-28'],
    29: ['fs-rem-18-24', 'fs-rem-29-39'],
    32: ['fs-rem-20-28', 'fs-rem-32-43'],
    34: ['fs-rem-23-32', 'fs-rem-34-45'],
  }
  return (
    <>
      <span
        className={`hiragino-sans fw-bold ${map[fs][0]} ${mfs !== 0 ? map[mfs][2] : ''}`}
        style={{ color: props.color }}
      >
        ¥
      </span>
      <span
        className={`optician-sans ${map[fs][1]} ${mfs !== 0 ? map[mfs][3] : ''}`}
        style={{ color: props.color, letterSpacing: fs >= 0 ? -1 : 0, marginLeft: fs >= 28 ? 1 : 0 }}
      >
        {value.toFixed(0)}.0
      </span>
    </>
  )
}
