import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { isMobile, isPad } from '../../../utils/rem'
import MoonriseIcon from '../../moonrise-icon'

import Info from '../info'
import Cart from '../cart'

export default function Dialog(props: {
  visible: {
    info: boolean
    cart: boolean
    mobile: boolean
  }
  onClose?: (type: string) => void
}) {
  const location = useLocation()
  const { visible } = props
  const [dialogHeight, setDialogHeight] = useState<string>(`100%`)

  useEffect(() => {
    if (isMobile()) {
      if (props.visible.info || props.visible.cart || props.visible.mobile) {
        setDialogHeight('101vh')
        return
      }
      setDialogHeight('100%')
    }
  }, [props])

  const header = (type: string) => {
    return (
      <div className={`nav-dialog_${type}--header`}>
        <span
          className="close color-fff cursor-pointer fs-rem-19-26 lh-rem-19-26 fs-m-34 lh-m-34"
          onClick={() => {
            props.onClose && props.onClose(type)
          }}
        >
          <MoonriseIcon name="icon-close" style={{ fontSize: 'inherit' }}></MoonriseIcon>
        </span>
      </div>
    )
  }

  return (
    <>
      <CSSTransition in={visible.info} unmountOnExit timeout={300} classNames="nav-dialog-transition">
        <div
          className="nav-dialog"
          onClick={() => {
            props.onClose && props.onClose('info')
          }}
        >
          <div className="nav-dialog_info" style={{ height: dialogHeight }}>
            {header('info')}
            <div className="nav-dialog_info--placeholder"></div>
            {isPad() ? (
              <div className="nav-dialog_info--pad">
                <div className="pad-placeholder"></div>
                <div className="pad-body">
                  <Info></Info>
                </div>
              </div>
            ) : (
              <div className="nav-dialog_info--body">
                <Info></Info>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
      <CSSTransition in={visible.cart} unmountOnExit timeout={300} classNames="nav-dialog-transition">
        <div
          className="nav-dialog"
          onClick={() => {
            props.onClose && props.onClose('cart')
          }}
        >
          <div className="nav-dialog_cart" style={{ height: dialogHeight }}>
            {header('cart')}
            <div className="nav-dialog_cart--placeholder"></div>
            {isPad() ? (
              <div className="nav-dialog_cart--pad">
                <div className="pad-placeholder"></div>
                <div className="pad-body">
                  <Cart></Cart>
                </div>
              </div>
            ) : (
              <div className="nav-dialog_cart--body">
                <Cart></Cart>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
      {(() => {
        if (visible.mobile) {
          return (
            <div
              className={`nav-dialog ${visible.mobile ? 'element-visible' : 'element-hidden'}`}
              onClick={() => {
                props.onClose && props.onClose('mobile')
              }}
            >
              <div className="nav-dialog_mobile" style={{ height: dialogHeight }}>
                {header('mobile')}
                <div className="nav-dialog_mobile--body flex-column flex-align-center">
                  {[
                    {
                      title: 'HOME',
                      path: '/'
                    },
                    {
                      title: 'FLOWERS',
                      path: '/flowers'
                    },
                    {
                      title: 'PRODUCT',
                      path: '/product'
                    }
                  ].map(nav => {
                    return (
                      <Link
                        key={nav.path}
                        className={`optician-sans fs-m-30 flex-center link link-${
                          location.pathname === nav.path ? 'active' : ''
                        }`}
                        to={nav.path}
                      >
                        {nav.title}
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }
      })()}
    </>
  )
}
