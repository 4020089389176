import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'

export interface IAppState {
  navStatus: boolean
}

export const appSlice = createSlice<IAppState, SliceCaseReducers<IAppState>>({
  name: 'app',
  initialState: {
    navStatus: false
  },
  reducers: {
    switchNavStatus: (state, action) => {
      state.navStatus = action.payload
    }
  }
})

export const { updateLiveRoom } = appSlice.actions
