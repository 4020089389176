import React from 'react'
import * as ReactDOM from 'react-dom/client'

import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import '@arco-design/web-react/dist/css/arco.css'
import './index.scss'

import { isProduction } from './constant/config'

import { Provider } from 'react-redux'
import store from './store'
import { px, onresize, isPad, isMobile } from './utils/rem'

import Nav from './components/nav'

import Loading from './components/loading'
import Loadable from './loadable'

const Starter = React.lazy(() => import('./components/starter'))
const Home = React.lazy(() => import('./pages/home'))

const Works = Loadable(() => import('./pages/works'))
const Product = Loadable(() => import('./pages/product'))
const Demo = Loadable(() => import('./pages/demo'))
const Error = Loadable(() => import('./pages/error'))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

let FAVICON_INDEX = 0
const FAVICON_IMAGES = [
  {
    href: '/favicon.png',
    time: 3000
  },
  {
    href: '/favicon2.png',
    time: 500
  }
]
const CHANGE_FAVICON = function () {
  const fa = document.querySelector('#favicon')
  if (fa) {
    FAVICON_INDEX++
    if (FAVICON_INDEX >= FAVICON_IMAGES.length) {
      FAVICON_INDEX = 0
    }
    fa.setAttribute('href', FAVICON_IMAGES[FAVICON_INDEX].href)
    setTimeout(() => {
      CHANGE_FAVICON()
    }, FAVICON_IMAGES[FAVICON_INDEX].time)
  }
}

document.documentElement.style.fontSize = `${px()}px`
onresize(0, () => {
  document.documentElement.style.fontSize = `${px()}px`
})

if (isPad()) {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
  // document.body.style.overflow = 'hidden'
} else {
  document.documentElement.style.setProperty('--vh', '100vh')
}

if (!isMobile() && !isPad()) {
  setTimeout(() => {
    CHANGE_FAVICON()
  }, FAVICON_IMAGES[FAVICON_INDEX].time)
}

root.render(
  <BrowserRouter>
    <React.Suspense fallback={<Loading />}>
      <Starter fallback={<Loading />}>
        <Provider store={store}>
          <Nav></Nav>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/demo" element={isProduction ? <Navigate replace to="/error" /> : <Demo />} />
            <Route path="/flowers" element={<Works />} />
            <Route path="/product" element={<Product />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<Navigate replace to="/error" />} />
          </Routes>
        </Provider>
      </Starter>
    </React.Suspense>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
