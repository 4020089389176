export const PRODUCTION_COST = 0.5

// export const TRANSFORM_STYLE: Record<
//   string,
//   any[]
// > = {
//   '9': [
//     {
//       x: 0,
//       y: 10,
//       rotate: '-20deg'
//     },
//     {
//       x: 30,
//       y: 0,
//       mirror: true,
//       rotate: '-10deg'
//     },
//     {
//       x: -40,
//       y: -45,
//       rotate: '-25deg'
//     },
//     {
//       x: 0,
//       y: -50,
//       rotate: '-15deg'
//     },
//     {
//       x: 50,
//       y: -70,
//       mirror: true,
//       rotate: '-20deg'
//     },
//     {
//       x: 15,
//       y: -110,
//       rotate: '-20deg'
//     },
//     {
//       x: 40,
//       y: -125,
//       mirror: true,
//       rotate: '-20deg'
//     },
//     {
//       x: 80,
//       y: -90,
//       mirror: true,
//       rotate: '-20deg'
//     },
//     {
//       x: -20,
//       y: -150,
//       rotate: '-20deg'
//     }
//   ],
//   '16': [
//     {
//       x: 10,
//       y: 90
//     },
//     {
//       x: -64,
//       y: 40,
//       rotate: '-10deg'
//     },
//     {
//       x: -8,
//       y: 50,
//       rotate: '8deg'
//     },
//     {
//       x: 50,
//       y: 36,
//       mirror: true,
//       rotate: '-20deg'
//     },
//     {
//       x: -100,
//       y: -8,
//       rotate: '-40deg'
//     },
//     {
//       x: -40,
//       y: -40,
//       rotate: '-20deg'
//     },
//     {
//       x: -20,
//       y: -64,
//       mirror: true,
//       rotate: '-10deg'
//     },
//     {
//       x: 20,
//       y: -30,
//       mirror: true,
//       rotate: '-20deg'
//     },
//     {
//       x: 120,
//       y: 0,
//       mirror: true,
//       rotate: '-35deg'
//     },
//     {
//       x: -120,
//       y: -90,
//       rotate: '-35deg'
//     },
//     {
//       x: -54,
//       y: -126,
//       rotate: '-10deg'
//     },
//     {
//       x: 60,
//       y: -60,
//       mirror: true,
//       rotate: '-28deg'
//     },
//     {
//       x: 130,
//       y: -70,
//       mirror: true,
//       rotate: '-45deg'
//     },
//     {
//       x: -130,
//       y: -156,
//       rotate: '-25deg'
//     },
//     {
//       x: 40,
//       y: -140,
//       mirror: true,
//       rotate: '-10deg'
//     },
//     {
//       x: 110,
//       y: -120,
//       mirror: true,
//       rotate: '-25deg'
//     }
//   ],
//   '25': [
//     {
//       x: -40,
//       y: 70,
//       rotate: '-10deg'
//     },
//     {
//       x: 30,
//       y: 80,
//       rotate: '1deg'
//     },
//     {
//       x: 100,
//       y: 70,
//       mirror: true,
//       rotate: '-10deg'
//     },
//     {
//       x: -80,
//       y: -0,
//       rotate: '-25deg'
//     },
//     {
//       x: -10,
//       y: 0,
//       rotate: '-15deg'
//     },
//     {
//       x: 65,
//       y: 5,
//       rotate: '-5deg'
//     },
//     {
//       x: 70,
//       y: 5,
//       mirror: true,
//       rotate: '-15deg'
//     },
//     {
//       x: 155,
//       y: 0,
//       mirror: true,
//       rotate: '-15deg'
//     },
//     {
//       x: -110,
//       y: -60,
//       mirror: true,
//       rotate: '35deg'
//     },
//     {
//       // 10
//       x: -30,
//       y: -65,
//       mirror: true,
//       rotate: '20deg'
//     },
//     {
//       x: 20,
//       y: -70,
//       rotate: '-2deg'
//     },
//     {
//       x: 90,
//       y: -75,
//       mirror: true,
//       rotate: '-5deg'
//     },
//     {
//       x: 130,
//       y: -70,
//       rotate: '20deg'
//     },
//     {
//       x: 200,
//       y: -70,
//       mirror: true,
//       rotate: '-30deg'
//     },
//     {
//       x: -140,
//       y: -90,
//       rotate: '-35deg'
//     },
//     {
//       x: -80,
//       y: -95,
//       rotate: '-10deg'
//     },
//     {
//       x: 10,
//       y: -115,
//       rotate: '5deg'
//     },
//     {
//       x: 110,
//       y: -120,
//       rotate: '15deg'
//     },
//     {
//       x: 180,
//       y: -130,
//       mirror: true,
//       rotate: '-32deg'
//     },
//     {
//       x: -100,
//       y: -180,
//       rotate: '-30deg'
//     },
//     {
//       x: -30,
//       y: -170,
//       rotate: '-15deg'
//     },
//     {
//       x: 40,
//       y: -195,
//       mirror: true,
//       rotate: '0deg'
//     },
//     {
//       x: 80,
//       y: -190,
//       rotate: '15deg'
//     },
//     {
//       x: 120,
//       y: -220,
//       mirror: true,
//       rotate: '-25deg'
//     },
//     {
//       x: 210,
//       y: -160,
//       mirror: true,
//       rotate: '-45deg'
//     }
//   ]
// }
