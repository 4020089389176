import './index.scss'

export default function Counter(props: {
  value: number
  min?: number
  size?: 'small' | 'medium'
  onChange?: (val: number) => void
}) {
  const { min = 1, size = 'small' } = props
  return (
    <div className="counter optician-sans">
      <div
        className={`counter_btn counter_btn-${size} ${props.value <= min ? `counter_btn-disabled` : ''}`}
        onClick={() => {
          if (props.value <= min) {
            return
          }
          props.onChange && props.onChange(-1)
        }}
      >
        <div className="line-h"></div>
      </div>
      <div className={`counter_val-${size} optician-sans`}>{props.value}</div>
      <div
        className={`counter_btn counter_btn-${size}`}
        onClick={() => {
          props.onChange && props.onChange(1)
        }}
      >
        <div className="line-h"></div>
        <div className="line-v"></div>
      </div>
    </div>
  )
}
