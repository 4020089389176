import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { IState } from '../store'
import { ICartState, ICartAddress } from '../store/cart'

const CART_ITEM_FROM_MAP: Record<Moonrise.Cart.ItemFrom, string> = {
  flower: '零售花材',
  works: 'FLOWERS',
  product: 'PRODUCT',
  diy: 'DIY CENTER'
}

type CartFromCategoryItem = {
  from: Moonrise.Cart.ItemFrom
  title: string
  list: Moonrise.Cart.Item[]
}

export function useCart(): ICartState {
  return useSelector<IState, ICartState>(state => state.cart)
}

export function useCartFromCategory() {
  const cart = useCart()
  const [cartFromCategory, setCartFromCategory] = useState<CartFromCategoryItem[]>([])

  useEffect(() => {
    setCartFromCategory(() => {
      return cart.list.reduce((res: CartFromCategoryItem[], item) => {
        let fIndex = res.findIndex(i => i.from === item.from)
        if (fIndex < 0) {
          res.push({
            from: item.from,
            title: CART_ITEM_FROM_MAP[item.from],
            list: []
          })
          fIndex = res.length - 1
        }
        res[fIndex].list.push(item)
        return res
      }, [])
    })
  }, [cart])

  return cartFromCategory
}

export function useCartCount() {
  const cart = useCart()
  const [cartCountMap, setCartCountMap] = useState<Record<string, number>>({})

  useEffect(() => {
    setCartCountMap(() => {
      return cart.list.reduce((obj: Record<string, number>, item) => {
        if (item.from === 'product' && item.spec) {
          obj[`${item.from}${item.id}${item.spec}`] = item.count
        } else {
          if (item.specId) {
            obj[`${item.from}${item.id}${item.specId}`] = item.count
          } else {
            obj[`${item.from}${item.id}`] = item.count
          }
        }
        return obj
      }, {})
    })
  }, [cart])

  return cartCountMap
}

export function useAddress() {
  return useSelector<IState, ICartAddress>(state => state.cart.address)
}
