import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { fetchOrderDisabledDate } from '../../../../service'

const MONTH_TEXT = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const WEEK_TEXT = ['日', '一', '二', '三', '四', '五', '六']

type Month = {
  month: string
  days: {
    num: number
    day: string
    disabled: boolean
  }[]
}

const formatYMD = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? '' : 0}${date.getMonth() + 1}-${
    date.getDate() > 9 ? '' : 0
  }${date.getDate()}`
}

const generateMonth = (month: Dayjs, now: Date) => {
  const forCount = month.daysInMonth()
  const _month: Month = {
    month: `${month.year()}-${month.month() + 1 > 9 ? '' : 0}${month.month() + 1}`,
    days: new Array(month.day())
  }
  _month.days.fill({
    num: 0,
    day: '',
    disabled: false
  })
  for (let index = 0; index < forCount; index++) {
    _month.days.push({
      num: index + 1,
      day: `${month.year()}-${month.month() + 1 > 9 ? '' : 0}${month.month() + 1}-${index + 1 > 9 ? '' : 0}${
        index + 1
      }`,
      disabled:
        `${month.year()}-${month.month()}` === `${now.getFullYear()}-${now.getMonth()}`
          ? index + 1 < now.getDate()
          : month.isBefore(now)
    })
  }
  return _month
}

export default function CartCalendar(props: { value?: Date; onChange?: (date: Date) => void }) {
  const [now] = useState(new Date())
  const [months, setMonths] = useState<Month[]>([])
  const [monthIndex, setMonthIndex] = useState<number>(1)
  const [disabledDate, setDisabledDate] = useState<string[]>([])

  useEffect(() => {
    const y = `${now.getFullYear()}`
    const m = `${now.getMonth() + 1 > 9 ? '' : 0}${now.getMonth() + 1}`
    const _monthday = `${y}-${m}-01`
    const _months = [dayjs(_monthday).subtract(1, 'month'), dayjs(_monthday), dayjs(_monthday).add(1, 'month')]
    setMonths(
      _months.map(month => {
        return generateMonth(month, now)
      })
    )
    fetchOrderDisabledDate().then(res => {
      setDisabledDate(
        res.data.disabledDate.map(num => {
          return formatYMD(new Date(num))
        })
      )
    })
  }, [now])

  useEffect(() => {
    if (disabledDate.length && !props.value) {
      if (disabledDate.indexOf(formatYMD(now)) < 0) {
        props.onChange && props.onChange(now)
      }
    }
  }, [disabledDate, now, props])

  return (
    <>
      <div className="changer pt-rem-30-43 pb-rem-27-40 pt-m-18 pb-m-18">
        <div className="flex-align-center flex-between lh-rem-23-31 lh-m-18">
          <div className="op-sans fs-rem-23-31 fs-m-18" style={{ color: 'var(--moonrise-color-green)' }}>
            {months.length ? MONTH_TEXT[Number(months[monthIndex].month.split('-')[1]) - 1].toUpperCase() : ''}
          </div>
          <div className="flex-align-center">
            <img
              className="pointer w-rem-6-8 width-m-5 changer_icon"
              src="https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/icon-calendar-switch.png"
              style={{ transform: 'rotate(180deg)', marginRight: 'var(--nav-cart--calendar-switch-mr)' }}
              alt="切换日期"
              onClick={() => {
                if (monthIndex >= 1) {
                  setMonthIndex(i => {
                    return i - 1
                  })
                }
              }}
            />
            <img
              className="pointer w-rem-6-8 width-m-5 mr-rem-8-15 mr-m-8 changer_icon"
              src="https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/icon-calendar-switch.png"
              alt="切换日期"
              onClick={() => {
                if (monthIndex === months.length - 2) {
                  console.log('+1')
                  setMonths(val => {
                    val.push(generateMonth(dayjs(`${val[monthIndex + 1].month}-01`).add(1, 'month'), now))
                    return val
                  })
                }
                setMonthIndex(i => {
                  return i + 1
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="carousel flex-column pb-rem-12-24">
        <div className="carousel_head flex-shrink flex">
          {WEEK_TEXT.map(text => {
            return (
              <div className="text-center flex-shrink hi-sans fw-bold fs-rem-16-27 fs-m-14 user-select-none" key={text}>
                {text}
              </div>
            )
          })}
        </div>
        <div className="carousel_body flex-grow flex">
          {months.map((m, i) => {
            return (
              <div
                className="carousel_body--month"
                key={i}
                style={{
                  opacity: i === monthIndex ? 1 : 0,
                  height: i === monthIndex ? 'auto' : 'var(--nav-cart--calendar-placeholder-height)',
                  transform: `translateX(-${100 * monthIndex}%)`
                }}
              >
                {m.days.map((day, dayIndex) => {
                  return (
                    <div
                      className={`day flex-shrink text-center${day.day === formatYMD(now) ? ' day-today' : ''}${
                        day.disabled || disabledDate.indexOf(day.day) >= 0 ? ' day-disabled' : ' day-normal'
                      }${
                        props.value &&
                        disabledDate.indexOf(formatYMD(props.value)) < 0 &&
                        day.day === formatYMD(props.value)
                          ? ' day-active'
                          : ''
                      }`}
                      key={dayIndex}
                    >
                      <span
                        className={`op-sans fs-rem-22-30 fs-m-18 user-select-none}`}
                        style={{ opacity: day.num > 0 ? 1 : 0 }}
                        onClick={() => {
                          if (day.disabled || disabledDate.indexOf(day.day) >= 0) {
                            return
                          }
                          props.onChange && props.onChange(dayjs(day.day).toDate())
                        }}
                      >
                        {day.num}
                      </span>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
