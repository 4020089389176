import './index.scss'
import React from 'react'

export default function MoonriseIcon(props: {
  name?: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}) {
  return (
    <i
      className={['iconfont', props.name || '', props.className || ''].join(' ')}
      style={props.style}
      onClick={props.onClick}
    ></i>
  )
}
